import Logo from "../assets/images/logo.svg";
import logoWhite from "../assets/images/logo_white.svg";
import darkMode from "../assets/icons/dark_mode.svg";
import search from "../assets/icons/search.svg";
import sideBar from "../assets/icons/side_bar.svg";
import Arrow from "../assets/icons/arrow.svg";
import ArrowRed from "../assets/icons/arrow_red.svg";
import videoPlay from "../assets/icons/video_play.svg";
import videoPlayWhite from "../assets/images/aboutUs/video_play_white.svg";
import Slider2 from "../assets/images/homeSlider/slider_2.webp";
import Slider4 from "../assets/images/homeSlider/slider_4.webp";
import Slider6 from "../assets/images/homeSlider/slider_6.webp";
import Slider9 from "../assets/images/homeSlider/slider_9.webp";
import experienceCenter from "../assets/images/services/experience_center.webp";
import experienceCenter1 from "../assets/images/services/experienceCenter/experience-center-1.webp";
import experienceCenter2 from "../assets/images/services/experienceCenter/experience-center-2.webp";
import marketing from "../assets/images/services/marketing.webp";
import rdSupport from "../assets/images/services/r&d_support.webp";
import technical from "../assets/images/services/technical.webp";
import worldMap from "../assets/images/world_map.svg";
import indiaMap from "../assets/images/india_map.webp";
import People from "../assets/images/sustainability/people.webp";
import Process from "../assets/images/sustainability/process.webp";
import Product from "../assets/images/sustainability/product.webp";
import sustainability3 from "../assets/images/sustainability/etp_for_sustainability1.webp";
import color from "../assets/images/whyChooseUs/color.svg";
import countries from "../assets/images/whyChooseUs/countries.svg";
import customers from "../assets/images/whyChooseUs/customers.svg";
import experience from "../assets/images/whyChooseUs/experience.svg";
import chat from "../assets/images/socialIcons/chat.svg";
import facebook from "../assets/images/socialIcons/facebook.svg";
import fb from "../assets/images/socialIcons/fb.svg";
import insta from "../assets/images/socialIcons/insta.svg";
import instagram from "../assets/images/socialIcons/instagram.svg";
import linkdin from "../assets/images/socialIcons/linked_in.svg";
import linkedIn from "../assets/images/socialIcons/linkedIn.svg";
import youTube from "../assets/images/socialIcons/youtube.svg";
import you_Tube from "../assets/images/socialIcons/you_Tube.svg";
import bt2024 from "../assets/images/bt-2024.gif";
import iplas2024 from "../assets/images/iplas-2024.gif";
import nonWoven2024 from "../assets/images/nonWoven-2024.gif";
import plastnepal2024 from "../assets/images/plastnepal-2024.png";
import bharatTex2025 from "../assets/images/bharatTex-2025.jpg";
import IntPlast2025 from "../assets/images/intPlast2025.jpg";
import Close from "../assets/icons/close.png";
import CloseBtn from "../assets/icons/close.svg";
import closeBlack from "../assets/icons/close_black.svg";
import closeWhite from "../assets/icons/close_white.svg";
import boardMembers from "../assets/images/aboutUs/board_members.webp";
import csr from "../assets/images/aboutUs/csr.webp";
import locations from "../assets/images/aboutUs/locations.webp";
import theCompany from "../assets/images/aboutUs/the_company.webp";
import csrBanner from "../assets/images/csr/csr_banner.webp";
import SSPoddar from "../assets/images/boardMember/SSPoddar.webp";
import ShriSSPoddar from "../assets/images/theCompany/ShriSSPoddar.webp";
import boardMember from "../assets/images/boardMember/board_member.webp";
import locationBanner from "../assets/images/locations/location_banner.webp";
import companyBanner from "../assets/images/theCompany/company_banner.webp";
import Play from "../assets/icons/play.png";
import Checked from "../assets/icons/checked.png";
import videoCamera from "../assets/icons/video-camera.png";
import Calendar from "../assets/icons/calendar.png";
import handshake from "../assets/icons/handshake.png";
import ourJourney from "../assets/images/theCompany/ourJourney.webp";
import WaterManagements1 from "../assets/images/sustainability/WaterManagement/w_1.webp";
import WaterManagement2 from "../assets/images/sustainability/WaterManagement/w_2.webp";
import WaterManagement3 from "../assets/images/sustainability/WaterManagement/w_3.webp";
import WaterManagement4 from "../assets/images/sustainability/WaterManagement/w_4.webp";
import SolarPowerEnvironment1 from "../assets/images/sustainability/SolarPowerEnvironment/s_1.webp";
import SolarPowerEnvironment2 from "../assets/images/sustainability/SolarPowerEnvironment/s_2.webp";
import SolarPowerEnvironment3 from "../assets/images/sustainability/SolarPowerEnvironment/s_3.webp";
import SolarPowerEnvironment4 from "../assets/images/sustainability/SolarPowerEnvironment/s_4.webp";
import HealthSafety1 from "../assets/images/sustainability/HealthSafety/h_1.webp";
import HealthSafety2 from "../assets/images/sustainability/HealthSafety/h_2.webp";
import HealthSafety3 from "../assets/images/sustainability/HealthSafety/h_3.webp";
import HealthSafety4 from "../assets/images/sustainability/HealthSafety/h_4.webp";
import FireSafty from "../assets/images/sustainability/HealthSafety/fire_safety.webp";
import healthCamp from "../assets/images/sustainability/HealthSafety/health_camp.webp";
import safety_committee from "../assets/images/sustainability/HealthSafety/safety_committee.webp";
import dust from "../assets/images/sustainability/dust/dust.webp";
import dust1 from "../assets/images/sustainability/dust/dust.webp";
import dust2 from "../assets/images/sustainability/dust/dust_2.webp";
import dust3 from "../assets/images/sustainability/dust/dust_3.webp";
import dust4 from "../assets/images/sustainability/dust/dust_4.webp";
import dying from "../assets/images/sustainability/dyeing.webp";
import rd1 from "../assets/images/services/rdservices/rd_1.webp";
import rd2 from "../assets/images/services/rdservices/rd_2.webp";
import rd3 from "../assets/images/services/rdservices/rd3.webp";
import rd4 from "../assets/images/services/rdservices/rd4.webp";
import rdBanner from "../assets/images/services/rdservices/rdBanner.webp";
import tss1 from "../assets/images/services/technicalSupport/tss_1.webp";
import tss2 from "../assets/images/services/technicalSupport/tss_2.webp";
import tss4 from "../assets/images/services/technicalSupport/tss_4.webp";
import tss5 from "../assets/images/services/technicalSupport/tss_5.webp";
import technical3 from "../assets/images/services/technical.webp";
import tecBanner from "../assets/images/services/tec_banner.webp";
import ms1 from "../assets/images/services/marketingSupport/ms_1.webp";
import ms2 from "../assets/images/services/marketingSupport/ms_2.webp";
import ms3 from "../assets/images/services/marketingSupport/ms_3.webp";
import ms5 from "../assets/images/services/marketingSupport/ms_5.webp";
import ms4 from "../assets/images/services/marketingSupport/ms_4.webp";
import ms6 from "../assets/images/services/marketingSupport/ms_6.webp";
import people_m from "../assets/images/sustainability/people_m.webp";
import ourJourneyDark from "../assets/images/theCompany/ourJourneyDark.webp";
import events from "../assets/images/newsEvents/events.jpg";
import GauravGoenka from "../assets/images/boardMember/GauravGoenka.webp";
import SSPoddar2 from "../assets/images/boardMember/SSPoddar.webp";
import LalithaKumarmangalam from "../assets/images/boardMember/LalithaKumarmangalam.webp";
import MKSonthalia from "../assets/images/boardMember/MKSonthalia.webp";
import Mahadevan from "../assets/images/boardMember/Mahadevan.webp";
import MahimaPAgarwal from "../assets/images/boardMember/MahimaPAgarwal.webp";
import NGopalaswamy from "../assets/images/boardMember/NGopalaswamy.webp";
import RMohan from "../assets/images/boardMember/R.Mohan.webp";
import bhandariJi from "../assets/images/boardMember/Bhandariji.webp";
import financials from "../assets/images/investorRelations/financials.webp";
import csrHome from "../assets/images/investorRelations/csrHome.webp";
import shareholdersInformation from "../assets/images/investorRelations/shareholders_information.webp";
import codesPolicies from "../assets/images/investorRelations/codes_policies.webp";
import WaterManagementNew from "../assets/images/sustainability/WaterManagementNew.webp";
import contactUs from "../assets/images/contactUs/contactUs.webp";
import FLogo from "../assets/images/FLogo.svg";
import FLogoDark from "../assets/images/FLogoDark.svg";
import digitalHome from "../assets/images/services/digitalHome.webp";
import digitalFiber1 from "../assets/images/services/digitalShade/DigitalFibre-1.webp";
import digitalFiber2 from "../assets/images/services/digitalShade/DigitalFibre-2.webp";
import digitalPlastics1 from "../assets/images/services/digitalShade/DigitalPlastics-1.webp";
import digitalPlastics2 from "../assets/images/services/digitalShade/DigitalPlastics-2.webp";
import investorEductaion from "../assets/images/investorRelations/investorEductaion.webp";
import stckExchange from "../assets/images/investorRelations/stckExchange.webp";
import comingSoon from "../assets/images/csr/coming_soon.webp";
import arrowRight from "../assets/icons/right-arrow.png";
import chatbot from "../assets/images/chatBot/chatbot.png";
import user from "../assets/images/chatBot/user.png";
import minus from "../assets/images/chatBot/minus-sign.png";
import CSRVideo from "../assets/images/csr/CSR Thumbnail.png";
import english from "../assets/images/languages/english.png";
import french from "../assets/images/languages/french.png";
import spainsh from "../assets/images/languages/spain.png";
import german from "../assets/images/languages/german.png";
import portugal from "../assets/images/languages/portugal.png";
import language from "../assets/images/languages/globe.png";
import CVVideo from "../assets/images/aboutUs/CV_video.jpg";

const Images = {
  chatbot,
  CSRVideo,
  minus,
  user,
  Logo,
  logoWhite,
  arrowRight,
  darkMode,
  search,
  sideBar,
  Arrow,
  ArrowRed,
  tss4,
  tss5,
  Slider2,
  Slider4,
  CVVideo,
  Slider6,
  Slider9,
  videoPlay,
  videoPlayWhite,
  comingSoon,
  experienceCenter,
  experienceCenter1,
  experienceCenter2,
  rdSupport,
  technical,
  worldMap,
  indiaMap,
  color,
  countries,
  customers,
  experience,
  chat,
  facebook,
  fb,
  insta,
  instagram,
  linkdin,
  linkedIn,
  youTube,
  you_Tube,
  Close,
  bt2024,
  iplas2024,
  nonWoven2024,
  plastnepal2024,
  bharatTex2025,
  IntPlast2025,
  boardMembers,
  csr,
  locations,
  theCompany,
  CloseBtn,
  closeBlack,
  closeWhite,
  csrBanner,
  boardMember,
  SSPoddar,
  ShriSSPoddar,
  locationBanner,
  companyBanner,
  safety_committee,
  dying,
  Play,
  Checked,
  videoCamera,
  Calendar,
  handshake,
  ourJourney,
  WaterManagements1,
  WaterManagement2,
  WaterManagement3,
  WaterManagement4,
  SolarPowerEnvironment1,
  SolarPowerEnvironment2,
  SolarPowerEnvironment3,
  SolarPowerEnvironment4,
  HealthSafety1,
  FireSafty,
  healthCamp,
  HealthSafety2,
  HealthSafety3,
  HealthSafety4,
  dust,
  dust1,
  dust2,
  dust3,
  dust4,
  rd1,
  rd2,
  rd3,
  rd4,
  marketing,
  rdBanner,
  tss1,
  tss2,
  technical3,
  tecBanner,
  ms1,
  ms2,
  ms3,
  ms5,
  ms4,
  ms6,
  people_m,
  ourJourneyDark,
  events,
  LalithaKumarmangalam,
  MKSonthalia,
  Mahadevan,
  MahimaPAgarwal,
  NGopalaswamy,
  RMohan,
  bhandariJi,
  SSPoddar2,
  GauravGoenka,
  financials,
  csrHome,
  codesPolicies,
  shareholdersInformation,
  WaterManagementNew,
  contactUs,
  FLogo,
  FLogoDark,
  digitalHome,
  digitalPlastics1,
  digitalPlastics2,
  digitalFiber2,
  digitalFiber1,
  investorEductaion,
  stckExchange,
  sustainability3,
  People,
  Process,
  Product,
  english,
  french,
  spainsh,
  portugal,
  german,
  language,
};
export default Images;
